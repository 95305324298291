<template>
    <section class="mt-5">
        <el-upload class="upload-demo"
                   drag
                   action="/index.php/index/talents_file/upload"
                   :headers= "config"
                   accept=".zip,.xls,.xlsx"
                   name="file"
                   :limit="1"
                   :on-success="handleAvatarSuccess"
                   :on-remove="handleRemove">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Drag the file here, or <em>click upload</em></div>
            <div class="el-upload__tip" slot="tip">xlsx, xls, zip file can be uploaded</div>
        </el-upload>
    </section>
</template>

<script>
    export default {
        name: "RegistrationFileUpload",
        data: function () {
            return {
                fileList: [{name: "", url: ""}],
                token:"",
            }
        },
        computed:{
            config() {
                return {'token' : this.token};
            }
        },
        created() {
            this.token =  sessionStorage.getItem('token');
        },
        methods: {
            handleRemove: function (e, t) {
                this.fileList = [];
                this.fileList = [{name: "", url: ""}]
            },
            del: function () {
                this.fileList = [];
                this.fileList = [{name: "", url: ""}]
            },
            handleAvatarSuccess: function (e, t, n) {
                this.$message.success("Upload success")
            },
            handleError: function (e, t, n) {
                let a = new RegExp('"msg":"([^"]*?)"', "gm"), i = a.exec(e.message);
                this.$message.error(unescape(i[1].replace(/\\/g, "%")))
            }
        }
    }
</script>

<style scoped>

</style>