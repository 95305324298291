import Vue from 'vue'
import Clipboard from 'clipboard'

// copy
function copySuccess(text) {
    Vue.prototype.$message({
        type: 'success',
        message: text + ' copy success',
        duration: 1500
    })
}
//not support
function copyFail() {
    Vue.prototype.$message({
        message: 'The browser does not support automatic copying',
        type: 'warning'
    })
}

export default function copyText(text,e) {
    const clipboard = new Clipboard(e.target, {
        text: () => text
    })
    clipboard.on('success', () => {
        copySuccess(text);
        // 释放内存
        clipboard.destroy();
    })
    clipboard.on('error', () => {
        // not support
        copyFail();
        // 释放内存
        clipboard.destroy();
    })
    // 解决第一次点击不生效的问题，如果没有，第一次点击会不生效
    clipboard.onClick(e)
}