<template>
    <section class="home-container">
        <section class="quick-menu">
            <el-row style="margin-left: -15px; margin-right: -15px;">
                <el-col :span="24" style="padding-left: 15px; padding-right: 15px;">
                    <div class="padding-50 bg-gray bg-search">
                        <div class="bg-img bg-img-1 flex-item-left-center">
                            <el-col :span="16" :offset="7" style="padding-left: 15px; padding-right: 15px;">
                                <h2>Search</h2>
                                <el-tabs v-model="activeName" v-if="userInfo.search_type>=1">
                                    <el-tab-pane label="Personal Professional Resources" name="first"
                                                 v-if="userInfo.search_type==1 || userInfo.search_type==2">
                                        <el-input v-model="keyword" type="primary" autocomplete="off"
                                                  class="custom-input"
                                                  @keyup.enter.native="handleSearch"
                                                  placeholder="Please input the keyword...">
                                            <el-button slot="append" type="primary" icon="el-icon-search"
                                                       @click="handleSearch"></el-button>
                                        </el-input>
                                    </el-tab-pane>
                                    <el-tab-pane label="Department Resources" name="second"
                                                 v-if="userInfo.search_type==1 || userInfo.search_type==3">
                                        <el-input v-model="keywordPlatform" type="primary" autocomplete="off"
                                                  class="custom-input"
                                                  @keyup.enter.native="handleSearchDepartmentResources"
                                                  placeholder="Please input the keyword...">
                                            <el-button slot="append" type="primary" icon="el-icon-search"
                                                       @click="handleSearchDepartmentResources"></el-button>
                                        </el-input>
                                    </el-tab-pane>
                                </el-tabs>
                            </el-col>
                        </div>
                    </div>
                </el-col>

                <template v-if="userInfo.superadmin">
                    <el-col :span="8" class="mt-30" style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/FileUpload'})">
                            <div class="bg-img bg-img-upload flex-item-left-top">
                                <p class="mt-20">File Upload</p>
                                <p class="mt-20"><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" class="mt-30" style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/Register'})">
                            <div class="bg-img bg-img-2 flex-item-left-top">
                                <p class="mt-20">Register</p>
                                <p class="mt-20"><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" class="mt-30" style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/Registration'})">
                            <div class="bg-img bg-img-3 flex-item-left-top">
                                <p>View <br>Personal Professional <br>Resources</p>
                                <p class="mt-20"><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="5" class="mt-30"
                            style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/FileUploadStatus'})">
                            <div class=" flex-item-left-top">
                                <p>File Upload<br> Status</p>
                                <p><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="5" class="mt-30"
                            style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/DepartmentMana'})">
                            <div class=" flex-item-left-top">
                                <p>Department<br> Management</p>
                                <p><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="5" class="mt-30"
                            style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/StaffMana'})">
                            <div class=" flex-item-left-top">
                                <p>Staff<br> Management</p>
                                <p><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="5" class="mt-30"
                            style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/UserFileUpload'})">
                            <div class=" flex-item-left-top">
                                <p>User Batch<br> Generation</p>
                                <p><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4" class="mt-30"
                            style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/LogList'})"
                             style="height: 79px;">
                            <div class=" flex-item-left-top">
                                <p>Log<br></p>
                                <p><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                </template>
                <template v-if="userInfo.superadmin==0 && userInfo.type==1">
                    <el-col :span="8" class="mt-30" style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/FileUpload'})">
                            <div class="bg-img bg-img-upload flex-item-left-top">
                                <p class="mt-20">File Upload</p>
                                <p class="mt-20"><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" class="mt-30" style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/Register'})">
                            <div class="bg-img bg-img-2 flex-item-left-top">
                                <p class="mt-20">Register</p>
                                <p class="mt-20"><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" class="mt-30" style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/Registration'})">
                            <div class="bg-img bg-img-3 flex-item-left-top">
                                <p>View <br>Registration<br> Information</p>
                                <p class="mt-20"><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="8" class="mt-30"
                            style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/FileUploadStatus'})">
                            <div class="flex-item-left-top">
                                <p>File Upload<br> Status</p>
                                <p><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" class="mt-30"
                            style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/StaffMana'})">
                            <div class="flex-item-left-top">
                                <p>Staff<br> Management</p>
                                <p><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" class="mt-30"
                            style="padding-left: 15px; padding-right: 15px;">
                        <div class="padding-50 bg-gray cursor" @click="$router.push({path: '/UserFileUpload'})">
                            <div class=" flex-item-left-top">
                                <p>User Batch<br> Generation</p>
                                <p><i class="el-icon-top-right"></i></p>
                            </div>
                        </div>
                    </el-col>
                </template>
            </el-row>
        </section>
    </section>
</template>

<script>

    export default {
        name: 'Home',
        data() {
            return {
                // activeName: 'first',
                userInfo: {},
                keyword: '',
                keywordPlatform: '',
            };
        },
        computed: {
            activeName() {
                if (this.userInfo.search_type == 3) {
                    return 'second';
                } else {
                    return 'first';
                }

            }
        },
        methods: {
            handleSearch() {
                if (this.keyword.trim() == '') {
                    this.$message({message: 'Please input the keyword！', type: 'warning'});
                    return;
                }
                this.$router.push({
                    path: "/Search",
                    name: "Search",
                    query: {keyword: this.keyword}
                })
            },
            handleSearchDepartmentResources() {
                if (this.keywordPlatform.trim() == '') {
                    this.$message({message: 'Please input the keyword！', type: 'warning'});
                    return;
                }
                this.$router.push({
                    path: "/DepartmentResourcesSearch",
                    name: "DepartmentResourcesSearch",
                    query: {keyword: this.keywordPlatform}
                })
            },
        },
        mounted() {
            this.userInfo = JSON.parse(sessionStorage.getItem('user'));
            // console.log(this.userInfo);
        }
    }
</script>

<style scoped>
    .quick-menu {
        margin-top: 30px;
    }

    .cursor {
        cursor: pointer;
    }

    .padding-50 {
        padding: 20px 10px 20px;
    }

    .bg-gray {
        background-color: #eaedf7;
        transition: all .3s ease-out
    }

    .bg-gray:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, .05);
        transform: scale(1.03)
    }

    .bg-gray h2, .bg-gray p {
        color: #097ff5;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 15px;
        padding: 5px
    }

    .bg-gray h2 {
        margin-top: -20px;
        padding-left: 0;
    }

    .bg-img {
        background-position: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        height: 160px
    }

    .bg-img-1 {
        background-image: url(../assets/img/search.svg);
        background-position: 0
    }

    .bg-img-2 {
        background-image: url(../assets/img/register.svg);
        height: 120px
    }

    .bg-img-3 {
        background-image: url(../assets/img/view.svg);
        height: 120px
    }

    .bg-img-upload {
        background-image: url(../assets/img/upload.svg);
        height: 120px;
    }

    .height-100 {
        height: 120px;
    }

    .custom-input {
        height: 48px;
        border-radius: 0
    }

    .custom-input >>> .el-input__inner {
        height: 48px;
        border-radius: 0;
        border-color: transparent
    }

    .custom-input >>> .el-input__inner:focus {
        border-color: #097ff5
    }

    .custom-input >>> .el-input-group__append {
        height: 46px;
        border-radius: 0
    }

    .custom-input >>> .el-button--primary {
        height: 46px;
        border-radius: 0;
        background-color: #097ff5;
        color: #fff;
        font-size: 22px;
        padding: 0 30px
    }

    .custom-input >>> .el-button--primary:hover {
        background-color: #0075f4
    }
</style>

 