import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import axios from 'axios'

import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI, {locale});

Vue.config.productionTip = false

Vue.prototype.$cookies = VueCookies;

function outlogin() {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('bologin');
    sessionStorage.removeItem('token');
    VueCookies.remove('LoginTime')
    // if (Vue.route.path != '/login') {
    //     console.log('router.path===' + router.path)
    //     router.push('/login');
    // }
    router.push('/login');
    return;
}

const tokenLoginTime = 1000 * 60 * 60 * 24;
// const tokenLoginTime = 1000 * 20;

function checkTimeOut() {
    // 当前时间 发送请求的时间 在请求拦截器调用这个函数 就是发送请求的当前时间
    const curTime = Date.now();
    // 登录时候的时间
    const loginTime = Number(VueCookies.get('LoginTime'));
    //   当前的时间 - 登录时候的时间 如果大于 24 小时 说明 token 过期了
    // console.log('cookie-loginTime====' + loginTime);
    // console.log('curTime====' + curTime);
    // var tt = curTime - loginTime;
    // console.log('LoginTime====' + tt);

    if (curTime > (loginTime + tokenLoginTime)) {
        return true; // 过期了
    } else {
        return false; //没有过期
    }
}

function WebApi(params, url = '') {
    if (checkTimeOut()) {
        outlogin()
    }

    if (!url) {
        url = '/index.php/index';
        url = url + '' + params.command;
    }
    // console.log('WebApi '+params.command);
    //开发环境-调试 发送数据
    // console.log(params);
    return axios.post(url, params.data,
        {
            headers: {'token': sessionStorage.getItem('token')}
        }
    ).then(res => {
        // console.log('http请求成功！');
        //开发环境-调试 返回数据
        // console.log(res.data);
        if (res.data.code != 200) {
            this.$message({
                message: '[' + res.data.code + ']' + res.data.msg,
                type: 'error'
            });
        }
        if (res.data.code == 99) {
            // console.log('身份失败！')
            outlogin();
        }
        // console.log(res)
        return res.data;
    }).catch(function (error) {
        // console.log('http 网络请求失败！');
        // console.log(error);
        return {code: -1, msg: 'Network request failed！'};
    });
}

function brightenKeyword(val, keyword) {
    const Reg = new RegExp(keyword, 'i');
    if (val) {
        return val.replace(Reg, `<span style="color: #ff0000;background:#ffff00;">${keyword}</span>`);
    }
}

router.beforeEach((to, from, next) => {

    if (to.path == '/login') {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('bologin');
        sessionStorage.removeItem('token');
        VueCookies.remove('LoginTime')
    }
    else if(to.path != '/noticePage'){
        if(checkTimeOut()){
            outlogin();
        }
    }

    // 如果用户未登录，则跳转到登录页面
    let bologin = JSON.parse(sessionStorage.getItem('bologin'));
    // if (!bologin && to.path != '/login') {
    //     next({path: '/login'})
    // } else {
    //     next()  // 用户已经登录，直接跳转
    // }
    if (!bologin) {
        if (to.path == '/noticePage') {
            next()
        } else if (to.path != '/login') {
            next({path: '/login'})
        } else {
            next()  // 用户已经登录，直接跳转
        }
    } else {
        next()  // 用户已经登录，直接跳转
    }

})

Vue.prototype.$WebApi = WebApi;
Vue.prototype.$brightenKeyword = brightenKeyword;
Vue.prototype.$outlogin = outlogin;

new Vue({
    router,
    // store,
    render: h => h(App)
}).$mount('#app')
