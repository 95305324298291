<template>
    <section class="mt-20">
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="LogList" name="first">
                <section v-loading="loading">
                    <div class="panel-search-toolbar">
                        <el-form inline ref="form">
                            <el-form-item label="* Code">
                                <el-input v-model="filter.username" class="custom-input"
                                          @keyup.enter.native="getlist"
                                          placeholder="Please input the Code..."
                                          clearable
                                          autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="Date">
                                <el-date-picker
                                        v-model="date"
                                        type="daterange"
                                        popper-class="custom-popper"
                                        unlink-panels
                                        range-separator="To"
                                        start-placeholder="Start"
                                        end-placeholder="End"
                                        value-format="yyyy-MM-dd"
                                        @change="setDate"
                                        :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="">
                                <el-button icon="el-icon-search" type="primary" @click="getlist">Search</el-button>
                            </el-form-item>
                        </el-form>
                        <el-form inline v-if="totalLogin>0">
                            <el-form-item label="Number of logins:">
                                {{totalLogin}}
                            </el-form-item>
                        </el-form>
                    </div>
                    <el-table :data="data" empty-text="No data" size="medium" stripe>
                        <el-table-column prop="id" label="#" width="70"></el-table-column>
                        <el-table-column prop="ip" label="IP" width="150"></el-table-column>
                        <el-table-column prop="username" label="Username" width="150"></el-table-column>
                        <el-table-column prop="action" label="Action" width="120"></el-table-column>
                        <el-table-column prop="create_time" label="Create Time" width="180"></el-table-column>
                        <!--            <el-table-column prop="content" label="Content"  :show-overflow-tooltip="true">-->
                        <el-table-column prop="content" label="Content">
                            <template slot-scope="scope">
                                <template v-if="scope.row.content.length>200">
                                    <el-tooltip class="item" effect="light" :content="scope.row.content" placement="bottom-end">
                                        <div class="log-content">{{scope.row.content.substring(0,200)}}...</div>
                                    </el-tooltip>
                                </template>
                                <template v-else>{{scope.row.content}}</template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="panel-pagination">
                        <el-pagination background
                                       layout="total, sizes, prev, pager, next"
                                       :page-sizes="[10, 20, 50, 100, 200]"
                                       @size-change="handleSizeChange"
                                       @current-change="handleCurrentChange"
                                       :page-size="filter.pageSize"
                                       :total="totalRows">
                        </el-pagination>
                    </div>
                </section>
            </el-tab-pane>
            <el-tab-pane label="Login ranking" name="second">
                <div class="panel-search-toolbar">
                    <el-form inline>
                        <el-form-item label="Login ranking">
                            <el-select style="width:250px;" v-model="days" placeholder="Please select the query option" @change="getLoginRanking">
                                <el-option label="Within 1 day" :value="1"></el-option>
                                <el-option label="Within 7 days" :value="7"></el-option>
                                <el-option label="Within 30 days" :value="30"></el-option>
                                <el-option label="Within 60 days" :value="60"></el-option>
                                <el-option label="Within 90 days" :value="90"></el-option>
                                <el-option label="Within 180 days" :value="180"></el-option>
                                <el-option label="Within 365 days" :value="365"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <el-table :data="loginData" empty-text="No data" size="medium" stripe>
                    <el-table-column type="index" label="#" width="70"></el-table-column>
                    <el-table-column prop="username" label="Username" width="200"></el-table-column>
                    <el-table-column prop="counts" label="Counts"></el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
    </section>


</template>

<script>

    export default {
        name: "DepartmentMana",
        data() {
            return {
                activeName:'second',
                data: [],
                loading: false,
                pickerOptions: {
                    shortcuts: [{
                        text: 'Last Week',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: 'Last Month',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: 'Last Three Months',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: 'Last Six Months',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: 'Last Year',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                date: '',
                filter: {
                    startDate: '',
                    endDate: '',
                    username: "",
                    page: 1,
                    pageSize: 10,
                },
                totalLogin: 0,
                totalPage: 0,
                totalRows: 0,
                showMore: false,

                days: '',
                loginData:[],
            };
        },
        methods: {
            handleSizeChange(val) {
                this.filter.pageSize = val;
                this.getlist();
            },
            handleCurrentChange(val) {
                this.filter.page = val;
                this.getlist();
            },
            setDate(v) {
                // console.log(v);
                if (v) {
                    this.filter.startDate = v[0];
                    this.filter.endDate = v[1];
                } else {
                    this.filter.startDate = '';
                    this.filter.endDate = '';
                }
                // console.log(this.filter)
            },
            getlist() {
                this.filter.username = this.filter.username.trim();
                if (this.filter.username == '') {
                    this.$message({type: 'warning', message: 'Please input the code!'});
                    return;
                }
                var loginParams = {
                    "command": "/log/listdata",
                    "data": this.filter
                };
                this.loading = true;
                this.$WebApi(loginParams).then(res => {
                    this.loading = false;
                    if (res.code != 200) {
                        return
                    }
                    this.data = res.data;
                    this.totalLogin = res.totalLogin;
                    this.totalPage = res.totalPage;
                    this.totalRows = res.totalRows;
                });
            },
            getLoginRanking(){
                var loginParams = {
                    "command": "/log/logindata",
                    "data": {
                        'days': this.days
                    }
                };
                this.loading = true;
                this.$WebApi(loginParams).then(res => {
                    this.loading = false;
                    if (res.code != 200) {
                        return
                    }
                    this.loginData = res.data;
                    // this.totalLogin = res.totalLogin;
                    // this.totalPage = res.totalPage;
                    // this.totalRows = res.totalRows;
                });
            }
        },
        mounted() {
            // this.getlist();
        }
    }
</script>

<style scoped>
    /*.el-picker-panel [slot=sidebar], .custom-popper .el-picker-panel__sidebar{*/
    /*    width: 145px;*/
    /*}*/
    /*.custom-popper >>> .el-picker-panel__body{*/
    /*    margin-left: 145px;*/
    /*}*/
    .el-form--inline .el-form-item {
        margin-bottom: 10px;
    }


</style>

