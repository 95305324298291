<template>
    <el-row class="page-container">
        <el-col :span="24" class="header head_width">
            <el-col :span="11" class="logo">
                <span>Professional Expertise Resource Database</span>
            </el-col>
            <el-col :span="13" class="tool">
                <el-dropdown trigger="hover">
                      <span class="el-dropdown-link userinfo-inner"
                            style="color: rgb(255, 255, 255); margin-right: 15px; cursor: pointer;">
                        <i class="el-icon-user"></i> {{userInfo.username}}
                          <span v-if="userInfo.department">[{{userInfo.department}}]</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="$router.push('/ModifyPassword')">Modify Password
                        </el-dropdown-item>
<!--                        <el-dropdown-item @click.native="handleOpenEmail">Modify Email</el-dropdown-item>-->
                        <el-dropdown-item v-if="userInfo.iswechat==1" @click.native="unbindWechat">Unbind Login
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button type="success" size="mini" @click="$router.push('/Home')"><i class="el-icon-s-home"></i> Home
                </el-button>
                <el-button plain size="mini" @click="logout"><i class="el-icon-circle-close"></i> Log Out</el-button>
            </el-col>
        </el-col>
        <el-col :span="24" class="main main_width">
            <section class="content-container">
                <div class="container">
                    <el-row class="mt-10">
                        <el-col :span="24" class="breadcrumb-container">
                            <el-breadcrumb separator="/" class="breadcrumb-inner">
                                <el-breadcrumb-item v-for="(item,index) in $route.matched"
                                                    :key="item.path+'-'+index"
                                                    :to="{path: item.path}">
                                    {{item.name}}
                                </el-breadcrumb-item>
                            </el-breadcrumb>
                        </el-col>
                    </el-row>
                    <el-col :span="24" class="content-wrapper">
                        <transition name="slide" mode="out-in">
                            <router-view></router-view>
                        </transition>
                    </el-col>
                </div>
            </section>
        </el-col>
<!--        <el-dialog title="Modify Email" :visible.sync="dialogVisible" width="500px">-->
<!--            <el-form style="padding-right: 20px;" label-width="70px"-->
<!--                     :model="editData" :rules="rules" ref="ruleForm">-->
<!--                <el-form-item label="Email" prop="email" type="email">-->
<!--                    <el-input v-model="editData.email"></el-input>-->
<!--                </el-form-item>-->
<!--            </el-form>-->
<!--            <span slot="footer" class="mr-20">-->
<!--                <el-button size="small" @click="dialogVisible = false">Cancel</el-button>-->
<!--                <el-button size="small" type="primary" @click="handleEditSubmit">Submit</el-button>-->
<!--            </span>-->
<!--        </el-dialog>-->
    </el-row>
</template>
<script>

    export default {
        components: {},
        data() {
            return {
                userInfo: {},
                // dialogVisible: false,
                // editData: {id: '', email: ''},
                // rules: {
                //     id: [{required: true, trigger: 'blur'},],
                //     email: [{required: true, message: 'Please enter the email', trigger: 'blur'},],
                // },
            }
        },

        methods: {
            //
            unbindWechat() {
                var _this = this;
                this.$confirm('Are you sure to unbind?', 'Tips', {
                    type: 'warning',
                    confirmButtonText: 'Sure',
                    cancelButtonText: 'Cancel',
                }).then(() => {
                    let params = {
                        "command": "/talents_user/unbindwechat",
                        "data": {}
                    };
                    this.$WebApi(params).then(res => {
                        if (res.code != 200) return;
                        this.userInfo.iswechat = 0;
                        sessionStorage.setItem('user', JSON.stringify(this.userInfo));
                        this.$message({type: 'success', message: 'unbind success'});
                    });
                }).catch(() => {
                    this.$message({type: 'info', message: 'cancel'});
                });
            },
            //退出登录
            logout() {
                var _this = this;
                this.$confirm('Are you sure to logout?', 'Tips', {
                    type: 'warning',
                    confirmButtonText: 'Sure',
                    cancelButtonText: 'Cancel',
                }).then(() => {
                    let params = {
                        "command": "/talents_login/logout",
                        "data": {}
                    };
                    this.$WebApi(params).then(res => {
                        if (res.code != 200) return
                        this.$message({type: 'success', message: 'logout success'});
                        _this.$outlogin();
                    });
                }).catch(() => {
                    this.$message({type: 'info', message: 'cancel'});
                });
            },
            // handleOpenEmail(){
            //     this.editData.email = this.userInfo.email;
            //     this.dialogVisible = true;
            // },
            //edit email
            // handleEditSubmit() {
            //     this.$refs['ruleForm'].validate((valid) => {
            //         if (valid) {
            //             let params = {
            //                 "command": "/talents_user/editemail",
            //                 "data": this.editData
            //             };
            //             this.$WebApi(params).then(res => {
            //                 if (res.code != 200) return
            //                 this.dialogVisible = false;
            //                 this.userInfo.email = this.editData.email;
            //                 sessionStorage.setItem('user', JSON.stringify(this.userInfo));
            //                 this.$message({type: 'success', message: "Email modified successfully!"});
            //             });
            //         } else {
            //             console.log('error submit!!');
            //             return false;
            //         }
            //     });
            // },
        },
        mounted() {
            //获取登录人信息
            this.userInfo = JSON.parse(sessionStorage.getItem('user'));
            //
            // this.editData.id = this.userInfo.id;
            // this.editData.email = this.userInfo.email;
        }
    }

</script>
<style scoped>

    .page-container {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%
    }

    .page-container .header {
        height: 60px;
        line-height: 60px;
        background: #097ff5;
        color: #fff
    }

    .page-container .header .tool {
        text-align: right;
        padding-right: 20px
    }

    .page-container .header .logo {
        height: 60px;
        font-size: 22px;
        padding-left: 10px;
        padding-right: 10px
    }

    .page-container .header .logo img {
        border-color: hsla(0, 0%, 100%, .2);
        border-right-width: 2px;
        border-right-style: solid;
        float: left;
        margin: 10px;
        padding-right: 10px;
        transition: all .5s ease
    }

    .page-container .header .logo .txt {
        color: #fff
    }

    .page-container .main {
        display: flex;
        position: absolute;
        top: 60px;
        bottom: 0;
        overflow: hidden
    }

    .page-container .content-container {
        flex: 1;
        overflow: auto;
        padding: 15px
    }

    .page-container .content-container .breadcrumb-container .title {
        width: 200px;
        float: left;
        color: #475669
    }

    .page-container .content-container .content-wrapper {
        box-sizing: border-box
    }
</style>

