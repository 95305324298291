<template>
    <section v-loading="authLinkLoading">
        <div class="notice-page-content" v-if="detailDataVisible">
            <div class="top">
                <h1>创建群聊</h1>
                <p>点击下方按钮进行创建</p>
            </div>
            <div class="middle">
                <h3>{{detailData.title}}</h3>
                <br>
                <p>{{detailData.content}}</p>
                <br>
                <p>备注信息</p>
                <br>
                {{detailData.description}}
                <br>
                <br>
                <el-button type="success" v-loading="loading" @click="createGroup">创建群聊</el-button>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                authLinkLoading: false,
                loading: false,

                authLink: '',
                unid: '',
                code: '',
                token: '',

                detailData: {},
                detailDataVisible: false,
            };
        },
        methods: {
            getAuthLink() {
                var params = {
                    "command": "/we_com/oauthLink",
                    "data": {
                        "unid": this.unid
                    }
                };
                this.authLinkLoading = true;
                this.$WebApi(params).then(res => {
                    this.authLinkLoading = false;
                    if (res.code != 200) return;
                    this.authLink = res.data;
                    window.location.href = this.authLink;
                    // console.log("authLink");
                    // console.log(this.authLink);
                });
            },
            getUserToken() {
                var params = {
                    "command": "/we_com/getUserToken",
                    "data": {
                        "code": this.code
                    }
                };
                // this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOiJaaGFuZ1poYW5nIn0.0S-fGPPZaGqi1FGuBpKkRA4dmESBb30hNEhvBqjEfAI';
                // this.getDetail();
                this.authLinkLoading = true;
                this.$WebApi(params).then(res => {
                    if (res.code != 200) {
                        this.authLinkLoading = false;
                        return;
                    }
                    this.token = res.token;
                    //sessionStorage.setItem('token', res.token);
                    // console.log("noticePage token");
                    // this.$message({type: 'primary', message: 'getUserToken，token:'+this.token});
                    // console.log(this.token);
                    this.getDetail();
                });
            },
            getDetail() {
                var params = {
                    "command": "/we_com/getDetail",
                    "data": {
                        "unid": this.unid
                    }
                };
                this.$WebApi(params).then(res => {
                    this.authLinkLoading = false;
                    if (res.code != 200) return;

                    this.detailData = res.data;
                    this.detailDataVisible = true;

                    // console.log("noticePage detailData");
                    // console.log(this.detailData);
                });
            },
            createGroup() {
                var params = {
                    "command": "/we_com/createGroup",
                    "data": {
                        "token": this.token,
                        "unid": this.unid
                    }
                };

                // this.$message({type: 'primary', message: 'token:'+this.token+'--,unid:'+this.unid});
                // this.$message({type: 'error', message: "createGroup"});

                this.loading = true;
                this.$WebApi(params).then(res => {
                    // this.$message({type: 'error', message: res});
                    this.loading = false;
                    if (res.code != 200) return;
                    this.$message({type: 'success', message: 'Create group successfully!'});
                });
            },
        },
        mounted() {

            // this.$message({type: 'success', message: location.href});

            this.unid = this.$route.query.unid;

            //获取wx code;
            var url = location.search; //获取url中"?"符后的字串
            var theRequest = new Object();
            if (url.indexOf("?") != -1) {
                var str = url.substr(1);
                var strs = str.split("&");
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
                }
            }
            this.code = theRequest.code;

            // console.log('unid');
            // console.log(this.unid);
            // console.log('code');
            // console.log(this.code);

            // this.$message({type: 'primary', message: 'code:'+this.code+'--,unid:'+this.unid});

            if (this.unid && !this.code) {
                // console.log('unid && !code');
                // this.$message({type: 'primary', message: 'unid && !code ->> getAuthLink'});
                this.getAuthLink();
            }
            if (this.unid && this.code) {
                // console.log('unid && code');
                // this.$message({type: 'primary', message: 'unid && code ->> getUserToken'});
                this.getUserToken();
            }
        }
    }
</script>

<style scoped>
    .notice-page-content {
        margin: auto;
        padding: 30px;
    }

    @media (min-width: 992px) {
        .notice-page-content {
            width: 768px;
        }
    }

    .notice-page-content .top {
        padding-bottom: 30px;
        border-bottom: 1px solid #dddddd;
    }

    .notice-page-content .top h1 {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .notice-page-content .middle {
        padding-top: 30px;
    }
</style>