<template>
    <section class="mt-20" v-loading="loading">
        <el-table :data="data" empty-text="No data" size="medium" stripe>
            <el-table-column type="index" label="#" width="80"></el-table-column>
            <el-table-column prop="file" label="File" width="400"></el-table-column>
            <el-table-column prop="filename" label="Error Filename" width="300"></el-table-column>
            <el-table-column prop="username" label="Username" width="160"></el-table-column>
            <el-table-column prop="read" label="Status" width="120">
                <template slot-scope="scope">
                    <el-tag :type="statusType[scope.row.read]" size="mini">{{status[scope.row.read]}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="Create Time" width="200"></el-table-column>
            <el-table-column prop="msg" label="Message"></el-table-column>
        </el-table>
        <div class="panel-pagination">
            <el-pagination background
                           layout="total, sizes, prev, pager, next"
                           :page-sizes="[10, 20, 50, 100, 200]"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :page-size="page.pageSize"
                           :total="page.totalRows">
            </el-pagination>
        </div>

    </section>
</template>

<script>

    export default {
        name: "DepartmentMana",
        data() {
            return {
                data: [],
                loading: false,
                status:['Processing','Success','Failed'],
                statusType:['warning','success','danger'],
                page: {
                    page: 1,
                    pageSize: 10,
                    totalPage: 0,
                    totalRows: 0,
                },
            };
        },
        methods: {

            handleSizeChange(val) {
                this.page.pageSize = val;
                this.getlist();
            },
            handleCurrentChange(val) {
                this.page.page = val;
                this.getlist();
            },
            getlist() {
                let search = { page: this.page.page, pageSize: this.page.pageSize};
                var loginParams = {
                    "command": "/talents_file/listdata",
                    "data": search
                };
                this.loading = true;
                this.$WebApi(loginParams).then(res => {
                    this.loading = false;
                    if (res.code != 200) {
                        return
                    }
                    this.data = res.data;
                    this.page.totalPage = res.totalPage;
                    this.page.totalRows = res.totalRows;
                });
            },
        },
        mounted() {
            this.getlist();
        }
    }
</script>

<style scoped>

</style>

