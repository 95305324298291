<template>
    <section class="mt-20" v-loading="loading">
        <div class="panel-search-toolbar mt-20" style="padding-bottom: 15px;">
            <el-button icon="el-icon-plus" size="small" type="success" @click="handleAdd">Add</el-button>
        </div>
        <el-table :data="data" empty-text="No data" size="medium" stripe>
            <el-table-column type="index" label="#" width="80"></el-table-column>
            <el-table-column prop="name" label="Department" width=""></el-table-column>
            <el-table-column label="">
                <template slot-scope="scope">
                    <el-button plain size="mini" type="primary"
                               @click="handleEdit(scope.$index, scope.row)">
                        <i class="el-icon-edit"></i><span> Edit </span></el-button>
                    <el-button plain size="mini" type="danger"
                               @click="handleDelete(scope.$index, scope.row)">
                        <i class="el-icon-delete"></i><span> Delete </span></el-button>
                </template>
            </el-table-column>

        </el-table>
        <div class="panel-pagination">
            <el-pagination background
                           layout="total, sizes, prev, pager, next"
                           :page-sizes="[10, 20, 50, 100, 200]"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :page-size="page.pageSize"
                           :total="page.totalRows">
            </el-pagination>
        </div>
        <el-dialog :title="dialogtitle" :visible.sync="dialogVisible" width="30%">
            <el-form style="padding-right: 20px;" label-width="100px"
                     :model="editData" :rules="rules" ref="ruleForm">
                <el-form-item label="Department" prop="name">
                    <el-input v-model="editData.name"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="mr-20">
                <el-button size="small" @click="dialogVisible = false">Cancel</el-button>
                <el-button size="small" type="primary" @click="handleEditSubmit">Submit</el-button>
            </span>
        </el-dialog>

    </section>
</template>

<script>

    export default {
        name: "DepartmentMana",
        data() {
            return {
                dialogVisible: false,
                dialogtitle: "",
                editData: {
                    name: "",
                },
                rules: {
                    name: [
                        {required: true, message: 'Please enter the name', trigger: 'blur'},
                    ],
                },

                data: [],
                loading: false,
                search: {},
                page: {
                    page: 1,
                    pageSize: 10,
                    totalPage: 0,
                    totalRows: 0,
                },
            };
        },
        methods: {

            handleEditSubmit() {
                var subcmd = "add";
                if (this.dialogtitle == "Edit") subcmd = "edit";
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        var dd = {
                            "command": "/talents_department/" + subcmd,
                            "data": this.editData
                        };
                        this.$WebApi(dd).then(res => {
                            if (res.code != 200) {
                                return
                            }
                            this.dialogVisible = false;
                            this.$message({type: 'success', message: this.dialogtitle + " success!"});
                            this.getlist();
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            handleAdd() {
                this.dialogVisible = true;
                this.dialogtitle = "Add";
                this.editData = {name: ""};
            },
            handleEdit(index, row) {
                this.dialogVisible = true;
                this.dialogtitle = "Edit";
                this.editData = Object.assign({}, row);
            },
            handleDelete(index, row) {
                this.$confirm('Are you sure to delete?', 'Tips', {
                    confirmButtonText: 'Sure',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    var dd = {
                        "command": "/talents_department/delete",
                        "data": {id: row.id}
                    };
                    this.$WebApi(dd).then(res => {
                        if (res.code != 200) return
                        this.$message({type: 'success', message: 'Delete success'});
                        this.getlist();
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'cancel'
                    });
                });
            },
            handleSizeChange(val) {
                this.page.pageSize = val;
                this.getlist();
            },
            handleCurrentChange(val) {
                this.page.page = val;
                this.getlist();
            },
            getlist() {
                var search = {...this.search, page: this.page.page, pageSize: this.page.pageSize};
                var loginParams = {
                    "command": "/talents_department/index",
                    "data": search
                };
                this.loading = true;
                this.$WebApi(loginParams).then(res => {
                    this.loading = false;
                    if (res.code != 200) {
                        return
                    }
                    this.data = res.data;
                    this.page.totalPage = res.totalPage;
                    this.page.totalRows = res.totalRows;
                });
            },
            handleReset: function (e) {
                this.$refs['form'].resetFields()
            }
        },
        mounted() {
            this.getlist();
        }
    }
</script>

<style scoped>

</style>

