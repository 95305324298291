<template>
    <section>
        <section v-if="!editVisible" class="mt-20" v-loading="loading">
            <el-table :data="data" empty-text="No data" size="medium" stripe class="custom-table"
                      ref="multipleTable"
                      @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="45"></el-table-column>
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <register :value="props.row" otype="readonly" :keyword="filter.keyword"></register>
                    </template>
                </el-table-column>
                <el-table-column type="index" label="#" width="50"></el-table-column>
                <el-table-column prop="department" label="Department" width="180"></el-table-column>
                <el-table-column prop="username" label="Code" width="180">
                    <template slot-scope="scope">
                        <span @click="handleCopy(scope.row.username,$event)"
                              v-html="$brightenKeyword(scope.row.username, filter.keyword)"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="education" label="Education" width="180"></el-table-column>
<!--                <el-table-column prop="contact" label="Tel" width="180"></el-table-column>-->
                <el-table-column prop="status" label="Status" width="120">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status == 0" type="warning" size="small">Not approved</el-tag>
                        <el-tag v-if="scope.row.status == 1" type="success" size="small">Approved</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="" min-width="270">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status == 0"
                                   type="success" size="mini" icon="el-icon-check" @click="handleApproved(scope.row)">
                            Approved
                        </el-button>
                        <template
                                v-if="userInfo.superadmin==1 || userInfo.ismanager==1 || (userInfo.type>0 && userInfo.department == scope.row.department)">
                            <el-button plain size="mini" type="primary"
                                       @click="handleEdit(scope.$index, scope.row)">
                                <i class="el-icon-edit"></i><span> Edit </span></el-button>
                            <el-button plain size="mini" type="danger"
                                       @click="handleDelete(scope.$index, scope.row)">
                                <i class="el-icon-delete"></i><span> Delete </span></el-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
            <div class="panel-pagination el-row">
                <el-col :span="8" style="text-align: left;">
                    <el-button @click="toggleSelection(data)" size="mini">Select All</el-button>
                    <el-button @click="toggleSelection()" size="mini">Clear</el-button>
                    <el-button @click="handleApproved()" size="mini" type="success" icon="el-icon-check"
                               :disabled="multipleSelection.length>0?false: true">Batch Approved
                    </el-button>
                </el-col>
                <el-col :span="16">
                    <el-pagination
                            background
                            layout="total, sizes, prev, pager, next"
                            :page-sizes="[10, 20, 50, 100, 200]"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :page-size="filter.pageSize"
                            :total="totalRows">
                    </el-pagination>
                </el-col>
            </div>
            <br>
            <br>
        </section>
        <section v-if="editVisible">
            <register v-if="editVisible" otype="edit" v-model="editForm" @close="handleBack"></register>
        </section>
    </section>
</template>

<script>

    import copyText from '@/utils/clipboard'
    import Register from "./Register";

    export default {
        name: "Registration",
        components: {Register},
        data() {
            return {
                userInfo: {},
                data: [],
                filter: {
                    keyword: "",
                    isSelect: false,
                    page: 1,
                    pageSize: 10,
                },
                multipleSelection: [],
                loading: false,
                totalPage: 0,
                totalRows: 0,
                editForm: {},
                editVisible: false,
            };
        },
        methods: {
            handleBack() {
                this.editForm = {};
                this.editVisible = false;
                this.getList();
            },
            handleCopy(text, event) {
                copyText(text, event);
            },
            handleEdit(index, row) {
                this.editForm = Object.assign({}, row);
                this.editVisible = true;
            },
            handleSizeChange(val) {
                this.filter.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.filter.page = val;
                this.getList();
            },
            handleDelete(index, row) {
                this.$confirm('Are you sure to delete?', 'Tips', {
                    confirmButtonText: 'Sure',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    var params = {
                        "command": "/talents_index/delete",
                        "data": {id: row.id}
                    };
                    this.$WebApi(params).then(res => {
                        if (res.code != 200) return;
                        this.$message({type: 'success', message: 'Delete success'});
                        this.getList();
                    });
                }).catch(() => {
                    this.$message({type: 'info', message: 'cancel'});
                });
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                        this.multipleSelection = rows;
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                    this.multipleSelection = [];
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                // console.log("multipleSelection")
                // console.log(this.multipleSelection);
            },
            handleApproved(row) {
                var ids = [];
                if (row) {
                    ids.push(row.id);
                } else {
                    this.multipleSelection.forEach(v => {
                        ids.push(v.id);
                    })
                }
                var params = {
                    "command": "/talents_index/editstatus",
                    "data": {id: ids}
                };
                this.$WebApi(params).then(res => {
                    if (res.code != 200) return;
                    this.getList();
                    this.$message({type: 'success', message: 'Approved success'});
                });
            },
            getList() {
                let params = {
                    "command": "/talents_index/index",
                    "data": this.filter
                };
                this.data = [];
                this.loading = true;
                this.$WebApi(params).then(res => {
                    this.loading = false;
                    if (res.code != 200) {
                        return;
                    }
                    this.data = res.data;
                    this.totalPage = res.totalPage;
                    this.totalRows = res.totalRows;
                });
            },

        },
        mounted() {
            this.userInfo = JSON.parse(sessionStorage.getItem('user'));
            let k = this.$route.query.keyword;
            if (k) this.filter.keyword = k;
            this.getList();
        }
    }
</script>

<style scoped>

    .custom-input {
        height: 40px;
        border-radius: 0
    }

    .custom-input >>> .el-input__inner {
        height: 40px;
        border-radius: 0;
        border-color: transparent
    }

    .custom-input >>> .el-input__inner:focus {
        border-color: #097ff5
    }

    .custom-table >>> .el-table__expanded-cell {
        padding-top: 0;
        padding-right: 0;
    }
</style>