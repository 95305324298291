<template>
    <section>
        <el-row>
            <el-col :span="24" style="margin-left: 250px;">
                <!--list-->
                <div v-for="(item,index) in dd.list" :key="'row-'+index+'-'+item.id">
                    <template v-if="otype=='readonly'">
<!--                        deals in infoLevelTable component 20220613(list show)-->
                        <p class="level-row">
                            <span class="custom-text-overflow" v-html="$brightenKeyword(item.name, keyword)"></span>
                        </p>
                    </template>
                    <template v-else>
                        <p class="level-row">
                            <el-select v-model="item.name"
                                       filterable
                                       remote
                                       size="small"
                                       reserve-keyword
                                       placeholder="Please input and select"
                                       :remote-method="getList"
                                       @change="handleSelect"
                                       loading-text="loading"
                                       no-data-text="no data"
                                       :loading="queryLoading"
                                       :allow-create="true"
                                       class="level-row-select"
                                       style="width:auto">
                                <el-option v-for="item in queryList"
                                           :key="item.id"
                                           :label="item.name"
                                           :value="item.name">
                                </el-option>
                            </el-select>
                            <el-button size="mini" type="text" class="custom-btn" icon="el-icon-delete"
                                       @click="del(index)"></el-button>
                        </p>
                    </template>
                    <info-level-row :level="nextLevel"
                                    :otype="otype"
                                    :keyword="keyword"
                                    :detailshow="detailshow"
                                    :header="header"
                                    :value="item"></info-level-row>
                </div>
                <!--add-->
                <template v-if="boInputV">
                    <p class="level-row">
                        <el-select v-model="inputdd"
                                   filterable
                                   remote
                                   size="small"
                                   reserve-keyword
                                   autofocus
                                   placeholder="Please input and select"
                                   :remote-method="getList"
                                   @change="handleSelect"
                                   loading-text="loading"
                                   no-data-text="no data"
                                   :loading="queryLoading"
                                   :allow-create="true"
                                   class="level-row-select"
                                   style="width: auto">
                            <el-option
                                    v-for="item in queryList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.name">
                            </el-option>
                        </el-select>
                    </p>
                </template>
                <template v-if=" otype!='readonly'">
                    <el-button size="mini" type="text" icon="el-icon-plus" class="custom-btn"
                               @click="boInputV=true">
                        <span v-for="(item,index) in header"
                              v-if="level+1==index"> {{item.name}}  </span>
                    </el-button>
                    <el-button v-if="boInputV" size="mini" type="text" class="custom-btn"
                               @click="boInputV=false">Cancel
                    </el-button>
                </template>
            </el-col>
        </el-row>
    </section>
</template>

<script>
    export default {
        name: "InfoLevelRow",
        props: {
            value: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            level: {
                type: Number,
                default: 0,
            },
            otype: {
                type: String,
                default: ""
            },
            keyword: {
                type: String,
                default: ''
            },
            detailshow: {
                type: Number,
                default: 0,
            },
            header: {
                type: Array,
                default: () => []
            }
        },
        watch: {
            value: function (v) {
                this.dd = v;
            },
            detailshow: function (v) {
                this.dd.is_show = v;
            }
        },
        computed: {},
        data() {
            return {
                boInputV: false,
                inputdd: "",
                nextLevel: this.level + 1,
                dd: this.value,
                queryList: [],
                queryLoading: false,
            }
        },
        methods: {
            getList(e) {
                if (e && e.trim() != "") {
                    let params = {
                        command: "/talents_level/level",
                        data: {
                            name: e,
                            depth: this.level
                        }
                    };
                    this.queryLoading = true;
                    this.$WebApi(params).then(res => {
                        this.queryLoading = false;
                        if (res.code == 200) {
                            this.queryList = res.data;
                        }
                    })
                }
            },
            doChangData(e, t, n) {
                let index = this.dd.list.findIndex((e) => {
                    return e.name == n;
                });
                if (index < 0) {
                    e.id = t;
                    e.name = n;
                }
                this.$set(this.dd, this.dd);
                this.inputdd = "";
                this.boInputV = false;
            },
            handleChange(e, t) {
                let a = this.queryList.find((t) => {
                    return t.name == e;
                });
                if (a) {
                    this.doChangData(t, a.id, a.name);
                } else {
                    let params = {
                        command: "/talents_level/add",
                        data: {
                            name: e,
                            pid: this.dd.id,
                            depth: this.level
                        }
                    };
                    this.$WebApi(params).then((res) => {
                        if (res.code == 200) {
                            this.doChangData(t, res.id, e);
                            this.getList();
                        }
                    })
                }
            },
            doAddData(id, name, details, is_show) {
                let index = this.dd.list.findIndex((function (v) {
                    return v.name == name;
                }));
                if (index < 0) {
                    this.dd.list.push({
                        id: id,
                        name: name,
                        details: details,
                        is_show: is_show,
                        list: []
                    })
                }
                this.$set(this.dd, this.dd);
                this.inputdd = "";
                this.boInputV = false;
            },
            handleSelect(e) {
                let n = this.queryList.find((v) => {
                    return v.name == e;
                });
                if (n) {
                    this.doAddData(n.id, n.name, '', 0);
                } else {
                    let params = {command: "/talents_level/add", data: {name: e, pid: this.dd.id, depth: this.level}};
                    this.$WebApi(params).then((res) => {
                        if (res.code == 200) {
                            this.doAddData(res.id, e, '', 0);
                            this.getList();
                        }
                    })
                }
            },
            del: function (e) {
                this.dd.list.splice(e, 1);
            },

        },
        mounted() {
        }
    }
</script>

<style scoped>


    .level-row {
        border-bottom: 1px solid #ddd;
        padding: 7px 0;
        /*height: 30px;*/
        box-sizing: border-box;
        line-height: 1;
    }

    .custom-btn {
        color: #999
    }

    .custom-btn:hover {
        color: brown
    }

    .item-row p {
        font-size: 14px
    }

    .level-row-select >>> .el-input__inner {
        width: 100%;
        border-radius: 0;
        border: none;
        padding: 0;
        height: 20px;
    }

    .level-row-select >>> .el-input-group__append {
        border-left: 1px solid #dcdfe6
    }

    .level-row-select-new >>> .el-input__inner {
        width: 100%;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #dcdfe6;
        padding: 0
    }

    .item-row >>> .el-button--mini {
        padding: 3px 0
    }

    .custom-btn {
        color: #999
    }

    .custom-btn:hover {
        color: brown
    }

    .custom-btn.el-button--mini {
        padding-left: 5px !important;
    }

    .custom-text-overflow {
        max-width: 240px;
        display: inline-block;
        background-color: #fff;
        /*height: 19px;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        /*display: -webkit-box;*/
        /*-webkit-line-clamp: 1; !* 限制在一个块元素显示的文本的行数 *!*/
        /*-webkit-box-orient: vertical; !* 垂直排列 *!*/
        /*word-break: break-all; !* 内容自动换行 *!*/
    }

    @media screen and (min-width: 1400px) {
        .custom-text-overflow {
            max-width: 240px;
        }
    }

</style>