<template>
    <section class="mt-20">
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="Personal Professional Resources" name="first">
                <p class="mt-10 fc-ElInfo fs-14">Personal Professional Resources File Upload:</p>
                <registration-file-upload></registration-file-upload>
            </el-tab-pane>
            <el-tab-pane label="Department Resources" name="second">
                <p class="mt-10 fc-ElInfo fs-14">Department Resources File Upload:</p>
                <platform-file-upload></platform-file-upload>
            </el-tab-pane>
        </el-tabs>
    </section>
</template>

<script>
    import RegistrationFileUpload from "./registration/RegistrationFileUpload";
    import PlatformFileUpload from "./platformResources/DepartmentResourcesFileUpload";

    export default {
        name: "FileUpload",
        components: {PlatformFileUpload, RegistrationFileUpload},
        data: function () {
            return {
                activeName: 'first',
            }
        },
    }
</script>

<style scoped>

</style>