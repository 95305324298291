import Vue from 'vue'
import VueRouter from 'vue-router'

import noticePage from '../views/noticePage.vue'
import login from '../views/login.vue'
import NotFound from '../views/404.vue'
import Layout from '../views/layout.vue'
import Home from '../views/Home.vue'
import DepartmentMana from '../views/DepartmentMana.vue'
import StaffMana from '../views/StaffMana.vue'
import ModifyPassword from '../views/modifyPassword.vue'
import FileUpload from '../views/FileUpload.vue'
import FileUploadStatus from '../views/FileUploadStatus.vue'
import LogList from '../views/LogList.vue'

import Register from '../views/registration/Register.vue'
import Registration from '../views/registration/Registration.vue'
import Search from '../views/registration/RegistrationSearch.vue'
import UserFileUpload from '../views/registration/UserFileUpload.vue'
import RegistrationFileUpload from '../views/registration/RegistrationFileUpload.vue'

import DepartmentResourcesSearch from '../views/platformResources/DepartmentResourcesSearch.vue'
import DepartmentResourcesFileUpload from '../views/platformResources/DepartmentResourcesFileUpload.vue'


Vue.use(VueRouter)

const routes = [
    {path: '/noticePage', name: 'NoticePage', component: noticePage},
    {path: '/login', name: 'Login', component: login},
    {path: '/404', component: NotFound, name: '', hidden: true},
    {path: '*', redirect: {path: '/404'}, hidden: true},
    {
        path: '/Home',
        component: Layout,
        name: 'Home',
        children: [
            {path: '/Home', component: Home, name: 'HomePage'},
            {path: '/DepartmentMana', component: DepartmentMana, name: 'DepartmentMana'},
            {path: '/StaffMana', component: StaffMana, name: 'StaffMana'},
            {path: '/Registration', component: Registration, name: 'Registration'},
            {path: '/FileUpload', component: FileUpload, name: 'FileUpload'},
            {path: '/FileUploadStatus', component: FileUploadStatus, name: 'FileUploadStatus'},
            {path: '/ModifyPassword', component: ModifyPassword, name: 'ModifyPassword'},
            {path: '/LogList', component: LogList, name: 'LogList'},

            {path: '/Search', component: Search, name: 'Search'},
            {path: '/Register', component: Register, name: 'Register'},
            {path: '/UserFileUpload', component: UserFileUpload, name: 'UserFileUpload'},
            {path: '/RegistrationFileUpload', component: RegistrationFileUpload, name: 'RegistrationFileUpload'},

            {
                path: '/DepartmentResourcesSearch',
                component: DepartmentResourcesSearch,
                name: 'DepartmentResourcesSearch'
            },
            {
                path: '/DepartmentResourcesFileUpload',
                component: DepartmentResourcesFileUpload,
                name: 'DepartmentFileUpload'
            },
        ]
    },
]

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

const router = new VueRouter({
    routes
})

export default router
