<template>
    <section>
        <template v-if="otype=='edit'">
            <div class="panel-search-toolbar mt-20" style="padding-bottom: 20px;">
                <el-button icon="el-icon-back" size="mini" @click="$emit('close')">Back</el-button>
            </div>
        </template>
        <div class="section-inner mt-20">
            <div class="section-part">
                <h4>Basic Information</h4>
                <el-form :model="form" :rules="rules" ref="ruleForm" size="small">
                    <el-row>
                        <el-col :span="7">
                            <template v-if="otype=='readonly'">
                                <el-form-item label="Department" label-width="90px">
                                    {{form.department}}
                                </el-form-item>
                            </template>
                            <template v-else>
                                <el-form-item prop="department" label="Department" label-width="90px">
                                    <el-select v-model="form.department" placeholder="Please select the department"
                                               :disabled="userInfo.superadmin==1?false:true"
                                               style="width: 100%">
                                        <el-option v-for="item in departmentList"
                                                   :key="item.name+'-'+item.id"
                                                   :label="item.name"
                                                   :value="item.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </template>
                        </el-col>
                        <el-col :span="7">
                            <template v-if="otype=='readonly'">
                                <el-form-item label="Code" label-width="100px">
                                    <span v-html="$brightenKeyword(form.username, keyword)"></span>
                                </el-form-item>
                            </template>
                            <template v-else>
                                <el-form-item prop="username" label="Code" label-width="100px">
                                    <el-input v-model="form.username" placeholder="Please enter the code"
                                              style="width: 100%"></el-input>
                                </el-form-item>
                            </template>
                        </el-col>
                        <el-col :span="10">
                            <template v-if="otype=='readonly'">
                                <el-form-item label="Highest Education" label-width="180px">
                                    {{form.education}}
                                </el-form-item>
                            </template>
                            <template v-else>
                                <el-form-item prop="education" label="Highest Education" label-width="180px">
<!--                                    <el-select v-model="form.education" placeholder="Please select the education"-->
<!--                                               style="width: 100%">-->
<!--                                        <el-option v-for="(item,index) in educationList"-->
<!--                                                   :key="index"-->
<!--                                                   :label="item"-->
<!--                                                   :value="item"></el-option>-->
<!--                                    </el-select>-->
                                    <el-input v-model="form.education" placeholder="Please enter the education"></el-input>
                                </el-form-item>
                            </template>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
<!--            <div class="section-part" style="overflow-x: auto;" :style="{'min-width':getMaxLevelCount*250+'px'}">-->
            <div class="section-part" style="overflow-x: auto;">
<!--                :rowwidth= "getMaxLevelCount*250"-->
                <info-level-table v-for="(item, index) in form.detail"
                                  :value="item"
                                  :key="index"
                                  :usertype="userInfo.type+''"
                                  :otype="otype"
                                  :keyword="keyword">

                </info-level-table>
                <p v-if="otype!=='readonly'">
                    <br>
                    <el-button class="el-icon-plus" type="success" @click="handleAdd"></el-button>
                </p>
            </div>
            <div class="section-part text-center" v-if="otype!=='readonly'">
                <div class="mt-12">
                    <el-button type="primary" @click="handleSubmit" :loading="loading">SUBMIT</el-button>
                    <el-button @click="handleReset">RESET</el-button>
                </div>
            </div>
        </div>
        <template v-if="otype=='add' || otype=='edit'">
            <br>
            <br>
        </template>
    </section>
</template>

<script>
    import InfoLevelTable from "./InfoLevelTable";

    export default {
        name: "Register",
        components: {InfoLevelTable},
        props: {
            value: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            otype: {
                type: String,
                default: "add"
            },
            keyword: {
                type: String,
                default: ''
            }
        },
        computed: {
            //2022.05.11
            //2022.05.12 replaced by header.length
            // getMaxLevelCount(){
            //     function _findCount(item,icount) {
            //         let imax=icount+1;
            //         for (let i = 0; i < item.list.length; i++) {
            //             let j=_findCount(item.list[i],icount+1)
            //             if (j>imax) imax=j
            //         }
            //         return imax;
            //     }
            //     // console.log(this.form.detail)
            //     function _findCount1(item) {
            //         let imax=0;
            //         for (let i = 0; i < item.length; i++) {
            //             let j=_findCount(item[i],0)
            //             if (j>imax) imax=j
            //         }
            //         return imax;
            //     }
            //     return _findCount1(this.form.detail)
            // },
        },
        watch: {
            value: function (e, t) {
                this.form = e;
                // console.log("Register value")
                // console.log(value)
            },
        },
        data() {
            return {
                userInfo: {},
                form: {
                    id: '',
                    department: '',
                    username: '',
                    education: '',
                    detail:[],
                },
                rules: {
                    username: [{required: true, message: 'Please enter the code', trigger: 'blur'},],
                    department: [{required: true, message: 'Please select the department', trigger: 'blur'},],
                    education: [{required: true, message: 'Please enter the education', trigger: 'blur'},],
                },
                loading: false,
                departmentList: [],
                // educationList: ['undergraduate', 'graduate student', 'doctor', 'post-doctoral'],
            }
        },
        methods: {
            getDepartment() {
                let params = {
                    "command": "/talents_department/index",
                    "data": {
                        page: 1,
                        pageSize: 999999
                    }
                };
                this.$WebApi(params).then(res => {
                    if (res.code != 200) {
                        return
                    }
                    this.departmentList = res.data;
                });
            },
            handleAdd() {
                let obj = {depth: 0, did: 0, dpid: 0, header: [], list: [], name: '', pid: 0};
                this.form.detail.push(obj);
            },

            handleSubmit() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        // console.log("handleSubmit");
                        // console.log(this.form);
                        // return;
                        let params = {
                            "command": "/talents_index/add",
                            "data": this.form
                        };
                        if (this.form.id || this.otype === 'edit') params.command = "/talents_index/edit";
                        this.loading = true;
                        this.$WebApi(params).then(res => {
                            this.loading = false;
                            if (res.code != 200) return;
                            this.loading = false;
                            if(this.otype==='add'){
                                this.form.id = res.id;
                                this.otype = 'edit';
                            }
                            this.$message({type: 'success', message: this.otype + " success"});
                        });
                    }
                });
            },
            handleReset() {
                if (this.otype == 'add') {
                    this.$refs['ruleForm'].resetFields();
                    this.form.detail = [];
                }
                else if (this.otype == 'edit') {
                    this.form.detail = [];
                }
            },
            __init: function () {
                this.form = this.value;
            }
        },
        mounted() {
            this.userInfo = JSON.parse(sessionStorage.getItem('user'));
            if (this.otype == 'add') {
                if (this.userInfo.superadmin != 1 && this.userInfo.type == 1) {
                    this.form.department = this.userInfo.department;
                }
            }
            if (this.otype == 'edit' || this.otype == 'add') {
                this.getDepartment();
            }
            if (this.otype == 'edit' || this.otype == 'readonly') {
                this.__init();
            }
        }
    }
</script>

<style scoped>

    .section-inner {
        border: 1px solid #ddd
    }

    .section-inner .section-part {
        padding: 15px 15px 30px 15px;
        border-bottom: 1px solid #ddd;
        overflow: hidden;
    }

    .section-inner .section-part h4 {
        padding: 15px;
        margin: -10px -15px 0 -15px
    }

    .section-inner .section-part:last-child {
        border-bottom: none
    }

</style>