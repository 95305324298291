<template>
    <section style="overflow: hidden"
             :style="otype!='readonly'?{'width':(getheader.length+1)*250+'px'}:{'width':getheader.length*250+'px'}">
        <el-row class="info-item el-row" :gutter="30" v-if="isKeywordHead()">
            <el-col :span="24">
                <el-row class="level-title">
                    <el-col style="width: 250px; height: 17px;" v-for="(item,index) in getheader" :key="index">
                        <template v-if="otype!='readonly'">
                            <el-input v-model="item.name"
                                      placeholder="Please input the header"
                                      style="width: 85%;"
                                      :style="{marginLeft:(index==0?'5px':'0')}"
                                      size="mini" class="level-row-select"></el-input>
                        </template>
                        <template v-else>
                            {{item.name}}
                        </template>
                    </el-col>
                    <template v-if="otype!='readonly'">
                        <el-col style="width: 250px;">
                            <el-button size="mini" type="text" class="custom-btn" icon="el-icon-plus"
                                       @click="handleAddLevel">Header
                            </el-button>
                        </el-col>
                    </template>
                </el-row>
                <el-row>
                    <template v-if="isListShow()">
                        <template v-if="otype=='readonly'">
                            <table class="el-table--medium custom-table-medium" style="margin-left: 250px;"
                                   cellspacing="0" cellpadding="0">
                                <tr v-for="ydd in rootarr">
                                    <td v-for="xdd in ydd" style="width: 250px;padding: 5px 0; line-height: 1.1;">
                                        <!--&lt;!&ndash;                                        {{xdd.id}}&ndash;&gt;{{xdd.y}}-{{xdd.x}}|-->
                                        <span v-html="$brightenKeyword(xdd.name, keyword)"></span>
                                    </td>
                                </tr>
                            </table>
                        </template>
                        <template v-else>
                            <info-level-row :header="root.header"
                                            :level="0"
                                            :value="root"
                                            :otype="otype"
                                            :detailshow="root.is_show"
                                            :keyword="keyword"></info-level-row>
                        </template>
                    </template>
                </el-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>
    import InfoLevelRow from "./InfoLevelRow";

    export default {
        name: "InfoLevelTable",
        components: {InfoLevelRow},
        props: {
            value: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            otype: {
                type: String,
                default: ""
            },
            usertype: {
                type: String,
                default: '1'
            },
            keyword: {
                type: String,
                default: ''
            },
            rowwidth: {
                type: Number,
                default: 250
            },

        },
        watch: {
            value: function (e, t) {
                this.root = e;
                this.root.id = this.e.pid;
                this.root.name = this.e.name;
                this.root.header = this.e.header;
                // this.root.header = this.e.header.filter(v=>v.name!='' && v.name!=null);
                this.root.list || (this.root.list = []);
                this.root.details = '';
                // console.log(this.root);
            },

            root: function (e, t) {
                // console.log('watch root');
                // console.log(e);

                if (this.otype == 'readonly') {
                    let arr = this.listToArray(e);
                    // console.log("arr:")
                    // console.log(arr);
                    this.rootarr = arr;
                }

                this.$emit("input", e)
            },

        },
        computed: {
            // getMaxLevelCount(){
            //     function _findCount(item,icount) {
            //         let imax=icount+1;
            //         for (let i = 0; i < item.list.length; i++) {
            //             let j=_findCount(item.list[i],icount+1)
            //             if (j>imax) {
            //                 imax=j
            //             }
            //         }
            //         return imax;
            //     }
            //     let tmp = _findCount(this.root,0);
            //     return tmp;
            // },
            getheader() {
                if (this.usertype == '0') {
                    if (this.keyword.length <= 0) {
                        // console.log("1")
                        return this.root.header;
                    }
                    if (this.isKeywordNeedFilter()) {
                        // console.log("2")
                        return [this.root.header[0]]
                    }
                    // console.log("3[]")
                    return [];
                } else {
                    return this.root.header;
                }
            },
        },
        data() {
            return {
                root: this.value,
                rootarr: [],
            }
        },
        methods: {
            listToArray(d) {
                let arr = []

                function set(x, y, d) {
                    for (; arr.length <= y;) {
                        arr.push(new Array());
                    }
                    for (; arr[y].length <= x;) {
                        arr[y].push({x: 0, y: 0, name: ''});
                    }
                    arr[y][x] = {x: x, y: y, name: d.name};// arr[y][x] = d;
                }

                function _toarray(x, y, ls) {
                    if (!Array.isArray(ls)) return y;
                    ls.forEach((v) => {
                        if (typeof v == null) return;
                        set(x, y, v);
                        y = _toarray(x + 1, y, v.list);
                        y++;
                    })
                    return y;
                }

                let dd = JSON.parse(JSON.stringify(d))
                _toarray(0, 0, dd.list);
                return arr;
            },

            isListShow() {
                if (this.usertype == '0') {
                    if (this.keyword.length <= 0) {
                        return true;
                    }
                    return false;
                }
                return true;
            },
            isKeywordHead() {
                if (this.usertype == '0') {
                    if (this.keyword.length <= 0) {
                        return true;
                    }
                    return this.isKeywordNeedFilter();
                }
                return true;
            },
            isKeywordNeedFilter() {
                function _find(item, s) {
                    // console.log(item.name);
                    // let nn = IsLetter(item.name) ? item.name.toLocaleLowerCase() : item.name;
                    let nn = item.name.toLocaleLowerCase();
                    let f = nn.indexOf(s);
                    if (f >= 0) {
                        // console.log("ok:index:"+f + " name :"+item.name)
                        return true;
                    }
                    for (let i = 0; i < item.list.length; i++) {
                        if (_find(item.list[i], s)) {
                            return true;
                        }
                    }
                    return false;
                }

                // function IsLetter(name) {
                //     let reg = /^[a-zA-Z]+$/;
                //     if (!reg.test(name)) {
                //         return false;
                //     }
                //     return true;
                // }

                if (this.keyword.length <= 0) {
                    return false;
                }
                // console.log("keyword  "+this.keyword);

                let tmp = _find(this.root, this.keyword.toLocaleLowerCase());
                // let tmp = _find(this.root, this.keyword);
                // console.log(tmp)
                return tmp;
            },


            __init: function () {
                let list = this.value.list;
                this.root = {
                    id: this.value.pid,
                    name: this.value.name,
                    header: this.value.header,
                    // header: this.value.header.filter(v=>v.name!='' && v.name!=null),
                    list: list || [],
                    details: '',
                    is_show: 0,
                }
            },
            handleAddLevel() {
                this.root.header.push({name: ''});
            },
        },
        mounted() {
            this.__init()
        }
    }
</script>

<style scoped>

    .header-info {
        color: #999;
        font-size: 14px;
        display: block;
        font-weight: 400
    }

    .info-item {
    }

    .info-item p {
        font-size: 14px;
    }

    .info-item .el-input__inner {
        width: 100%;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #dcdfe6;
        padding: 0
    }

    .info-item .el-input-group__append {
        border-left: 1px solid #dcdfe6
    }

    .info-item .el-button--mini {
        padding: 3px
    }

    .level-title {
        padding: 6px 0;
        font-size: 12px;
        background-color: rgb(245, 247, 250);
        margin-bottom: 10px;
        color: rgb(102, 102, 102);
        font-weight: bold;
    }

    .custom-checkbox >>> .el-checkbox__label {
        padding-left: 2px;
    }

    .level-row-select >>> .el-input__inner {
        width: 100%;
        border-radius: 0;
        border: none;
        padding: 0;
        padding-left: 5px;
        height: 20px;
    }

    .level-row-select >>> textarea {
        width: 100%;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #dcdfe6;
        padding: 0;
        font-weight: bold;
        font-size: 16px;
        color: #000;
    }

    .el-table--enable-row-hover .el-table__body tr:hover > td {
        background-color: #fafbfc;
    }

    .custom-table-medium {
        margin-top: -10px;
    }
    .custom-table-medium tr:first-child td {
        border: none;
    }

    .custom-table-medium td {
        border-top: 1px solid #EBEEF5;
        border-bottom: none;
        /*border-bottom: 0.5px solid #EBEEF5;*/
    }
</style>