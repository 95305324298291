<template>
    <section class="mt-20" v-loading="loading">
        <div class="panel-search-toolbar mt-20" style="padding-bottom: 15px;">
            <el-row>
                <el-col :span="12">
                    <el-button icon="el-icon-plus" size="small" type="success" @click="handleAdd">Add</el-button>
                </el-col>
                <el-col :span="12" class="text-right">
                    <el-button v-if="userInfo.superadmin!=1 && userInfo.type==1"
                               @click="getInitializePass" size="mini" type="warning"
                               icon="el-icon-download"
                               :disabled="loading">Initialize staffs' password
                    </el-button>
                </el-col>
            </el-row>
        </div>
        <el-table :data="data" empty-text="No data" size="medium" stripe
                  ref="multipleTable"
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column type="index" label="#" width="50"></el-table-column>
            <el-table-column prop="username" label="Code" width="140">
                <template slot-scope="scope">
                    <span @click="handleCopy(scope.row.username,$event)">{{scope.row.username}}</span>
                </template>
            </el-table-column>
<!--            <el-table-column prop="email" label="Email" width="280"></el-table-column>-->
<!--            <el-table-column prop="contact" label="Tel" width="200">-->
<!--                <template slot-scope="scope">-->
<!--                    {{scope.row.contact}}-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="department" label="Department" width="160"></el-table-column>
            <el-table-column prop="type" label="Type" width="100">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.type == 1" type="primary" size="small">Leader</el-tag>
                    <el-tag v-if="scope.row.type == 2" type="success" size="small">Manager</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="ismanager" label="Manager" width="100">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.ismanager == 1" type="success" size="small">Manager</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="superadmin" label="Superadmin" width="120">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.superadmin == 1" type="warning" size="small">Administrator</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="Allow Login" width="100">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status == 0" type="success" size="small">Allow</el-tag>
                    <el-tag v-if="scope.row.status == 1" type="danger" size="small">Not Allow</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="search_type" label="Search Type" width="210">
                <template slot-scope="scope">
                    <!-- <el-tag v-if="scope.row.search_type == 0" type="danger" size="small">Not Allow</el-tag>-->
                    <span v-if="scope.row.search_type == 0" class="st-style">Not Allow</span>
                    <span v-if="scope.row.search_type == 1" class="st-style">Personal Professional Resources,<br> Department Resources</span>
                    <span v-if="scope.row.search_type == 2" class="st-style">Personal Professional Resources</span>
                    <span v-if="scope.row.search_type == 3" class="st-style">Department Resources</span>
                </template>
            </el-table-column>
            <el-table-column label="" min-width="260" fixed="right">
                <template slot-scope="scope">
                    <el-button plain size="mini" type="success" icon="el-icon-edit"
                               @click="handleEditPWD(scope.row)">Edit PWD
                    </el-button>
                    <el-button plain size="mini" type="primary" icon="el-icon-edit"
                               @click="handleEdit(scope.row)">Edit
                    </el-button>
                    <el-button plain size="mini" type="danger" icon="el-icon-delete"
                               v-if="scope.row.username != userInfo.username"
                               @click="handleDelete(scope.row)">Delete
                    </el-button>
                </template>
            </el-table-column>

        </el-table>
        <div class="panel-pagination el-row">
            <el-col :span="12" style="text-align: left;">
                <el-button @click="toggleSelection(data)" size="mini">Select All</el-button>
                <el-button @click="toggleSelection()" size="mini">Clear</el-button>
                <el-button @click="handleAllow()" size="mini" type="success" icon="el-icon-check"
                           :disabled="multipleSelection.length>0?false: true">Batch Allow Login
                </el-button>
                <el-button @click="handleNotAllow()" size="mini" type="danger" icon="el-icon-close"
                           :disabled="multipleSelection.length>0?false: true">Batch Not Allow Login
                </el-button>

            </el-col>
            <el-col :span="12">
                <el-pagination background
                               layout="total, sizes, prev, pager, next"
                               :page-sizes="[10, 20, 50, 100, 200]"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               :page-size="page.pageSize"
                               :total="page.totalRows">
                </el-pagination>
            </el-col>
        </div>

        <el-dialog custom-class="responsive-dialog" :title="dialogtitle" :visible.sync="dialogVisible" width="500" @close="handleCancel">
            <el-form style="padding-right: 20px;" label-width="140px"
                     :model="editData" :rules="editData.superadmin==1?rulessuper:rules" ref="ruleForm">
                <el-form-item label="Code" prop="username" v-if="dialogtitle=='Edit' || dialogtitle=='Add' ">
                    <el-input v-model="editData.username"></el-input>
                </el-form-item>
                <template v-if="dialogtitle=='Modify Password' || dialogtitle=='Add' ">
                    <el-form-item label="Password" prop="firstPassword">
                        <el-input v-model="editData.firstPassword" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="Confirm Password" prop="password">
                        <el-input v-model="editData.password" show-password></el-input>
                    </el-form-item>
                </template>
                <template v-if="dialogtitle=='Edit' || dialogtitle=='Add' ">
<!--                    <el-form-item label="Email" prop="email" type="email">-->
<!--                        <el-input v-model="editData.email"></el-input>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="Tel" prop="contact" type="tel">-->
<!--                        <el-input v-model="editData.contact"></el-input>-->
<!--                    </el-form-item>-->
                    <!--                    <template v-if="editData.superadmin==1">-->
                    <!--                        <el-form-item label="Department">-->
                    <!--                            <el-select v-model="editData.department"-->
                    <!--                                       placeholder="Please select your department"-->
                    <!--                                       style="width: 100%">-->
                    <!--                                <el-option v-for="item in department_data"-->
                    <!--                                           :key="item.name"-->
                    <!--                                           :label="item.name"-->
                    <!--                                           :value="item.name">-->
                    <!--                                </el-option>-->
                    <!--                            </el-select>-->
                    <!--                        </el-form-item>-->
                    <!--                    </template>-->
                    <!--                    <template v-else>-->
                    <el-form-item label="Department" prop="department">
                        <el-select v-model="editData.department"
                                   :disabled="userInfo.superadmin ==1 ? false : true"
                                   placeholder="Please select your department"
                                   style="width: 80%; margin-right: 2%">
                            <el-option v-for="item in department_data"
                                       :key="item.name"
                                       :label="item.name"
                                       :value="item.name">
                            </el-option>
                        </el-select>
                        <el-button style="width: 18%" @click="handleResetDept">Reset</el-button>
                    </el-form-item>
                    <!--                    </template>-->
<!--                    <el-form-item label="WeCom ID" prop="wecom_id" type="">-->
<!--                        <el-input v-model="editData.wecom_id"></el-input>-->
<!--                    </el-form-item>-->
                    <el-form-item label="Type" prop="type">
                        <el-radio-group v-model="editData.type" size="small">
                            <el-radio-button label="0">Staff</el-radio-button>
                            <el-radio-button label="1">Leader</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <!--                    :disabled="userInfo.superadmin!=1?true:false"-->
                    <el-form-item label="Manager" prop="ismanager">
                        <el-radio-group v-model="editData.ismanager" size="small">
                            <el-radio-button label="1">Yes</el-radio-button>
                            <el-radio-button label="0">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <!--                    <el-form-item label="Superadmin" prop="superadmin" v-if="userInfo.superadmin==1">-->
                    <el-form-item label="Superadmin" prop="superadmin"
                                  v-if="userInfo.superadmin==1 && dialogtitle=='Add' ">
                        <el-radio-group v-model="editData.superadmin" size="small" @change="handleSuperadminRadio">
                            <el-radio-button label="1">Yes</el-radio-button>
                            <el-radio-button label="0">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="Allow Login" prop="status">
                        <el-radio-group v-model="editData.status" size="small">
                            <el-radio-button label="0">Yes</el-radio-button>
                            <el-radio-button label="1">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="Search Type" prop="search_type">
                        <el-radio-group v-model="editData.search_type" size="small">
                            <el-radio border :label="0">Not Allow</el-radio>
                            <br>
                            <el-radio border :label="1">Personal Professional Resources, Department Resources</el-radio>
                            <br>
                            <el-radio border :label="2">Personal Professional Resources</el-radio>
                            <br>
                            <el-radio border :label="3">Department Resources</el-radio>
                        </el-radio-group>
                    </el-form-item>

                </template>
            </el-form>
            <span slot="footer" class="mr-20">
                <el-button size="small" @click="handleCancel">Cancel</el-button>
                <!--                <el-button size="small" @click="handleReset">Reset</el-button>-->
                <el-button size="small" type="primary" @click="handleEditSubmit">Submit</el-button>
            </span>
        </el-dialog>

    </section>
</template>

<script>
    import copyText from '@/utils/clipboard'
    import FileSaver from 'file-saver'
    import * as XLSX from 'xlsx'

    export default {
        name: "StaffMana",
        data() {
            return {
                userInfo: {},
                dialogVisible: false,
                dialogtitle: "",
                editData: {
                    username: "",
                    // email: "",
                    // contact: "",
                    password: "",
                    department: "",
                    type: 0,
                    ismanager: 0,
                    superadmin: 0,
                    firstPassword: "",
                    status: 1,
                    search_type: 0,
                    // wecom_id: '',
                },
                rules: {
                    username: [{required: true, message: 'Please enter the code', trigger: 'blur'},],
                    // email: [{required: true, message: 'Please enter the email', trigger: 'blur'},],
                    password: [{required: true, message: 'Please enter the password again', trigger: 'blur'},],
                    firstPassword: [{required: true, message: 'Please enter the password', trigger: 'blur'},],
                    department: [{required: true, message: 'Please select your department', trigger: 'blur'},],
                    type: [{required: true, message: 'Please select the type', trigger: 'blur'},],
                    superadmin: [{required: true, message: 'Please select the superadmin', trigger: 'blur'},],
                    status: [{required: true, message: 'Please select the status', trigger: 'blur'},],
                    search_type: [{required: true, message: 'Please select the search type', trigger: 'blur'},],
                },
                rulessuper: {
                    username: [{required: true, message: 'Please enter the code', trigger: 'blur'},],
                    // email: [{required: true, message: 'Please enter the email', trigger: 'blur'},],
                    password: [{required: true, message: 'Please enter the password again', trigger: 'blur'},],
                    firstPassword: [{required: true, message: 'Please enter the password', trigger: 'blur'},],
                    type: [{required: true, message: 'Please select the type', trigger: 'blur'},],
                    superadmin: [{required: true, message: 'Please select the superadmin', trigger: 'blur'},],
                    status: [{required: true, message: 'Please select the status', trigger: 'blur'},],
                    search_type: [{required: true, message: 'Please select the search type', trigger: 'blur'},],
                },
                department_data: [],
                loading: false,
                data: [],
                search: {},
                page: {
                    page: 1,
                    pageSize: 10,
                    totalPage: 0,
                    totalRows: 0,
                },

                multipleSelection: [],

                downloadExcelData: [],
            };
        },
        methods: {
            handleCancel() {
                this.dialogVisible = false;
                this.$refs['ruleForm'].clearValidate();
            },
            handleSuperadminRadio(v) {
                this.$refs['ruleForm'].clearValidate();
            },
            handleCopy(text, event) {
                copyText(text, event);
            },
            handleSizeChange(val) {
                this.page.pageSize = val;
                this.getlist();
            },
            handleCurrentChange(val) {
                this.page.page = val;
                this.getlist();
            },
            handleEditSubmit() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        let subcmd;
                        let data = {};
                        if (this.dialogtitle == "Edit") {
                            subcmd = "edit";
                            data = {
                                id: this.editData.id,
                                username: this.editData.username,
                                // email: this.editData.email,
                                // contact: this.editData.contact,
                                ismanager: this.editData.ismanager + '',
                                superadmin: this.editData.superadmin + '',
                                department: this.editData.department,
                                type: this.editData.type,
                                status: this.editData.status,
                                search_type: this.editData.search_type,
                                // wecom_id: this.editData.wecom_id
                            }
                        } else if (this.dialogtitle == "Modify Password") {
                            subcmd = "password";
                            data = {
                                username: this.editData.username,
                                firstPassword: this.editData.firstPassword,
                                password: this.editData.password
                            };
                        } else {
                            subcmd = "add";
                            data = this.editData;
                        }
                        let params = {
                            "command": "/talents_user/" + subcmd,
                            "data": data
                        };
                        this.$WebApi(params).then(res => {
                            if (res.code != 200) return
                            this.dialogVisible = false;
                            this.$message({type: 'success', message: this.dialogtitle + " success!"});
                            this.getlist();
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            handleAdd() {
                this.dialogVisible = true;
                this.dialogtitle = "Add";
                this.editData = {
                    username: "",
                    // email: "",
                    // contact: "",
                    password: "",
                    department: this.userInfo.department ? this.userInfo.department : "",
                    type: 0,
                    ismanager: 0,
                    superadmin: 0,
                    status: 1,
                    search_type: 0,
                    firstPassword: "",
                    // wecom_id: "",
                };
            },
            handleEditPWD(row) {
                this.dialogVisible = true;
                this.dialogtitle = "Modify Password";
                this.editData = Object.assign({}, row);
            },
            handleEdit(row) {
                this.dialogVisible = true;
                this.dialogtitle = "Edit";
                this.editData = Object.assign({}, row);
            },
            handleDelete(row) {
                this.$confirm('Are you sure to delete?', 'Tips', {
                    confirmButtonText: 'Sure',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        "command": "/talents_user/delete",
                        "data": {id: row.id}
                    };
                    this.$WebApi(params).then(res => {
                        if (res.code != 200) return;
                        this.$message({type: 'success', message: 'Delete success'});
                        this.getlist();
                    });
                }).catch(() => {
                    this.$message({type: 'info', message: 'cancel'});
                });
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                        this.multipleSelection = rows;
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                    this.multipleSelection = [];
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            handleNotAllow(row) {
                this.handleSubmit(row, 1);
            },
            handleAllow(row) {
                this.handleSubmit(row, 0);
            },
            handleSubmit(row, status) {
                var ids = [];
                if (row) {
                    ids.push(row.id);
                } else {
                    this.multipleSelection.forEach(v => {
                        ids.push(v.id);
                    })
                }
                var params = {
                    "command": "/talents_user/editstatus",
                    "data": {id: ids, status: status}
                };
                this.$WebApi(params).then(res => {
                    if (res.code != 200) return;
                    this.getlist();
                    this.$message({type: 'success', message: 'Allow login succeeded'});
                });
            },

            getInitializePass() {
                var header = [{name: 'Code', prop: 'username'}, {name: 'Password', prop: 'password'}]
                var params = {
                    "command": "/talents_user/resetpassword",
                    "data": {}
                };
                this.loading = true;
                this.$WebApi(params).then(res => {
                    this.loading = false;
                    if (res.code != 200) return;
                    var content = res.data;
                    if (content.length <= 0) {
                        this.$message({type: 'warning', message: 'No data'});
                        return;
                    }

                    var now = this.getTime();
                    var filename = this.userInfo.department + '_' + now + '.xlsx';
                    this.createTable(header, content, filename);
                });
            },
            createTable(header, content, filename) {
                var table = document.createElement('table');
                var thead = document.createElement('thead');
                var tbody = document.createElement('tbody');
                var tr = document.createElement('tr');
                table.appendChild(thead);
                table.appendChild(tbody);
                thead.appendChild(tr);
                //
                header.forEach((item, index) => {
                    var th = document.createElement('td');
                    th.innerHTML = item.name;
                    tr.appendChild(th);
                });
                //
                content.forEach((item) => {
                    var tr = document.createElement('tr');
                    tbody.appendChild(tr);
                    header.forEach((item2) => {
                        var td = document.createElement('td');
                        td.innerHTML = item[item2.prop];
                        tr.appendChild(td);
                    });
                })
                //
                var wb = XLSX.utils.table_to_book(table);
                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    bookSST: true,
                    type: 'array'
                });
                try {
                    FileSaver.saveAs(
                        new Blob([wbout], {type: "application/octet-stream"}), filename
                    )
                } catch (e) {
                    if (typeof console !== "undefined") console.log(e, wbout);
                }

            },
            getTime() {
                var g = new Date().getTime();
                var now = new Date(g);
                var y = now.getFullYear();
                var m = now.getMonth() + 1;
                var d = now.getDate();
                var h = now.getHours();
                var mi = now.getMinutes();
                var s = now.getSeconds();
                return (y + '-' + m + '-' + d + '-' + h + '-' + mi + '-' + s);
            },

            getlist() {
                let search = {...this.search, page: this.page.page, pageSize: this.page.pageSize};
                let params = {
                    "command": "/talents_user/index",
                    "data": search
                };
                this.loading = true;
                this.$WebApi(params).then(res => {
                    this.loading = false;
                    if (res.code != 200) {
                        return
                    }
                    this.data = res.data;
                    this.page.totalPage = res.totalPage;
                    this.page.totalRows = res.totalRows;
                });
            },
            handleReset: function (e) {
                this.$refs['form'].resetFields()
            },
            handleResetDept: function (e) {
                this.editData.department = '';
            },
            getlist_department() {
                let search = {page: 1, pageSize: 99999};
                let params = {
                    "command": "/talents_department/index",
                    "data": search
                };
                this.$WebApi(params).then(res => {
                    if (res.code != 200) {
                        return
                    }
                    this.department_data = res.data;
                });
            },
        },
        mounted() {
            this.userInfo = JSON.parse(sessionStorage.getItem('user'));
            this.getlist();
            this.getlist_department();
        }
    }
</script>

<style scoped>
    .el-tag {
        border-radius: 0;
    }

    .st-style {
        font-size: 12px;
        line-height: 16px;
        display: inline-block;
    }

    .el-radio.is-bordered {
        margin-bottom: 5px;
    }

    .el-radio.is-bordered + .el-radio.is-bordered {
        margin-left: 0 !important;
    }
    @media screen and (max-width:1024px) {
        .page-container >>> .el-dialog {
            width: 90%;
        }
    }
</style>

