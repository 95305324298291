<template>
    <section>
        <br>
        <br>
        <el-form style="max-width: 400px;" label-width="140px" size="small"
                 :model="form" :rules="rules" ref="ruleForm">
            <el-form-item label="Password" prop="firstPassword">
                <el-input v-model="form.firstPassword" show-password></el-input>
            </el-form-item>
            <el-form-item label="Confirm Password" prop="password">
                <el-input v-model="form.password" show-password></el-input>
            </el-form-item>
            <el-form-item class="mt-40">
                <el-button type="primary" :loading="loading" @click="handleSubmit">Submit</el-button>
                <el-button @click="handleReset">Reset</el-button>
            </el-form-item>
        </el-form>
    </section>
</template>

<script>
    export default {
        name: "ModifyPassword",
        data() {
            return {
                userInfo: {},
                form: {
                    username: "",
                    password: "",
                    firstPassword: ""
                },
                rules: {
                    username: [{required: true, message: 'Please enter the code', trigger: 'blur'},],
                    password: [{required: true, message: 'Please enter the password again', trigger: 'blur'},],
                    firstPassword: [{required: true, message: 'Please enter the password', trigger: 'blur'},],
                },
                loading: false,
            }
        },
        methods: {
            handleReset() {
                this.$refs['ruleForm'].resetFields();
                this.__init();
            },
            handleSubmit() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        let params = {
                            "command": "/talents_user/password",
                            "data": this.form
                        };
                        this.loading = true;
                        this.$WebApi(params).then(res => {
                            this.loading = false;
                            if (res.code != 200) return;
                            this.$message({type: 'success', message: "modify success"});
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            __init() {
                this.form.username = this.userInfo.username;
            },
        },
        mounted() {
            this.userInfo = JSON.parse(sessionStorage.getItem('user'));
            this.__init();
        }
    }
</script>

<style scoped>

</style>